@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.centerHam {
  display: flex;
  justify-content: center;
  align-items: center;
}


.max-width {
  @apply lg:mx-auto lg:px-24 xl:px-48 px-5;
}

.bg-gradient {
  @apply bg-gradient-to-r from-sky-500 via-purple-500 to-pink-600;
}

.text-gradient {
  @apply bg-clip-text bg-gradient text-transparent;
}

.section {
  @apply pt-28 xl:pt-28 2xl:pt-44;
}

.text-content {
  @apply dark:text-light-content text-dark-content;
}

body {
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* For active state */
.active-nav-link {
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  transform: scale(1.2);
  z-index: 2;
  font-size: 18px;
}


/* For unhovered state */
nav ul li a {
  display: inline-block;
  text-shadow: none;
  transform: scale(1);
  z-index: 1;
  transition: transform 0.3s ease, text-shadow 0.3s ease, font-size 0.3s ease;
  font-size: 16px;
  padding: 0 4px;
}

/* For hovered state */
nav ul li a:hover {
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  transform: scale(1.2);
  /* Increase the size slightly */
  z-index: 2;
  /* Bring the hovered element to the front */
  font-size: 18px;

}


.name-logo {
  font-size: 2.5rem;
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.name-logo1 {
  font-size: 1.5rem;
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.name-logo2 {
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(86, 255, 37, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.gradient-button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  border-radius: 25px;
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%);
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 1em;
  line-height: 1em;
  transition: all 0.3s ease;
}

.gradient-button:hover {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.image-container {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.image-item {
  width: 100%;
  flex-shrink: 0;
}

.slideshowSlider {
  display: flex;
  transition: transform 1s ease-in-out;
}

.slideshow {
  width: 50%;
  /* Adjust as needed */
  margin: 0 auto;
  overflow: hidden;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.md\:w-1\/2 {
  width: 100%;
}

.project-card {
  transition: all 0.3s ease;
  /* This is to make the changes happen smoothly */
}

.project-card:hover {
  transform: scale(1.05);
  /* This makes the card slightly larger */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  /* This adds a deeper shadow */
}

.flex-wrap>.project-card {
  display: flex;
  flex-direction: column;
}

.flex-wrap>.project-card>.dark\:bg-dark-card {
  flex-grow: 1;
}

/* Initially hide the hamburger menu */
.hamburger-menu {
  display: none;
}

/* CSS for the Projects scrolling menu */
@media (max-width: 1569px) {
  #countrySelection-wrapper {
    padding: 0 20px; /* reduce padding */
  }
  #countrySelection ul li {
    margin: 16px 12px; /* reduce margin */
    font-size: 14px; /* reduce font-size */
  }
  /* Additional styles for the hamburger menu */
  .hamburger-menu {
    display: block; /* show hamburger */
  }
  /* Hide your existing menu */
  #countrySelection-items {
    display: none; /* hide existing menu */
  }
}

.mobile-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: transparent;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


#countrySelection {
  position: relative;

}

#countrySelection::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%);
  z-index: 1;
}

#countrySelection-wrapper {
  display: flex;
  /* Add this */
  align-items: center;
  /* Add this */
  justify-content: center;
  /* Add this */
  position: relative;
  overflow: hidden;
  height: 100px;
  padding: 0 100px;
}

#countrySelector {
  position: absolute;
  /* Add this */
  left: 50%;
  /* Add this */
  top: 50%;
  transform: translateX(-50%, -50%);
  /* Add this */
  /* rest of the properties */
}

#countrySelector {
  position: relative;
  margin-left: -5px;
  top: -1px;
  width: 20px;
  /* or however wide you want the SVG to be */
  height: 20px;
  /* or however tall you want the SVG to be */
  background-size: contain;
  background-repeat: no-repeat;
}


#countrySelection ul li {
  display: inline-block;
  margin: 16px 24px;
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(86, 255, 37, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.countrySelection-paddle-left,
.countrySelection-paddle-right {
  cursor: pointer;
  border: none;
  position: absolute;
  top: 20px;
  background-color: transparent;
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.slick-prev,
.countrySelection-paddle-left {
  left: 0;
}

#yearSelection {
  position: relative;

}

#yearSelection::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  /* background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%); */
  z-index: 1;
}


#yearSelection-wrapper {
  display: flex;
  /* Add this */
  align-items: center;
  /* Add this */
  justify-content: center;
  /* Add this */
  position: relative;
  overflow: hidden;
  height: 100px;
  padding: 0 100px;
}

#yearSelector {
  position: absolute;
  /* Add this */
  left: 50%;
  /* Add this */
  top: 50%;
  transform: translateX(-50%, -50%);
  /* Add this */
  /* rest of the properties */
}

#yearSelector {
  position: relative;
  margin-left: -5px;
  top: -1px;
  width: 20px;
  /* or however wide you want the SVG to be */
  height: 20px;
  /* or however tall you want the SVG to be */
  background-size: contain;
  background-repeat: no-repeat;
}

#yearSelection ul li {
  display: inline-block;
  margin: 16px 24px;
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(86, 255, 37, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}



.yearSelection-paddle-left,
.yearSelection-paddle-right {
  cursor: pointer;
  border: none;
  position: absolute;
  top: 20px;
  background-color: transparent;
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

#PPSelection {
  position: relative;

}


#PPSelection::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  /* background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(176, 51, 145, 255) 100%); */
  z-index: 1;
}




#PPSelection-wrapper {
  display: flex;
  /* Add this */
  align-items: center;
  /* Add this */
  justify-content: center;
  /* Add this */
  position: relative;
  overflow: hidden;
  height: 100px;
  padding: 0 100px;
}


#PPSelector {
  position: absolute;
  /* Add this */
  left: 50%;
  /* Add this */
  top: 50%;
  transform: translateX(-50%, -50%);
  /* Add this */
  /* rest of the properties */
}

#PPSelector {
  position: relative;
  margin-left: -5px;
  top: -1px;
  width: 20px;
  /* or however wide you want the SVG to be */
  height: 20px;
  /* or however tall you want the SVG to be */
  background-size: contain;
  background-repeat: no-repeat;
}


.active p,
.active a {
  color: #fff !important;
}

.active {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.non-active {
  opacity: 0.3;
  transition: opacity 0.3s ease;
}





#PPSelection ul li {
  display: inline-block;
  margin: 16px 24px;
  background: linear-gradient(to right, rgba(19, 163, 234, 255) 0%, rgba(86, 255, 37, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.PPSelection-paddle-left,
.yearSelection-paddle-right {
  cursor: pointer;
  border: none;
  position: absolute;
  top: 20px;
  background-color: transparent;
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}


.slick-prev,
.yearSelection-paddle-left {
  left: 0;
}

.slick-prev,
.PPSelection-paddle-left {
  left: 0;
}

.slick-prev,
.icon-chevronleft {
  transform: rotate(180deg);
}

.icon-chevronleft,
.icon-chevronright {
  background-image: url('http://www.res-testing.com/images/arrow.png');
  background-repeat: no-repeat;
  background-size: 20px;
}

.arrow {
  width: 25px;
  margin-left: auto;
  margin-right: auto;
}

.slick-next,
.countrySelection-paddle-right {
  right: 0;
}

.slick-next,
.yearSelection-paddle-right {
  right: 0;
}

.slick-next,
.PPSelection-paddle-right {
  right: 0;
}